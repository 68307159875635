import Head from 'next/head';
import Link from 'next/link';
import PageTemplate from '../components/PageTemplate';
const Custom404 = () => {
  // todo: make something beautiful
  return <>
			<Head data-sentry-element="Head" data-sentry-source-file="404.tsx">
				<title>404 - Guided Trekking</title>
				<meta name="robots" content="noindex" data-sentry-element="meta" data-sentry-source-file="404.tsx" />
			</Head>
			<PageTemplate data-sentry-element="PageTemplate" data-sentry-source-file="404.tsx">
				<h1>404 - page not found</h1>
				Visit the <Link href="/" data-sentry-element="Link" data-sentry-source-file="404.tsx">homepage</Link> instead?
			</PageTemplate>
		</>;
};
export default Custom404;